/**
 * { data-analysis:  ['read', 'write'] }
 */
// 裁决
const judge = (actions, perm) => {
  // 用户权限为空则无权限
  if (!perm || !perm.length) return false; // 用户权限为所有权限则 有所有权限

  if (perm.join('') === '*') return true; // actions 每一项都在用户权限中 则有权限

  return actions.every(action => perm.includes(action));
}; // 校验


const _auth = (params, userPermission) => {
  const {
    resource,
    actions = []
  } = params; // 当前 resource 是一个正则

  if (resource instanceof RegExp) {
    // 获取用户所具备权限的key值数组
    const permKeys = Object.keys(userPermission); // 获取符合正则匹配的key

    const matchPermissions = permKeys.filter(item => item.match(resource));
    return matchPermissions.every(key => {
      const perm = userPermission[key];
      return judge(actions, perm);
    });
  } // 当前 resource 是一个字符串


  const perm = userPermission[resource];
  return judge(actions, perm);
};

const auth = (params, userPermission) => {
  // 解构鉴权值
  const {
    requiredPermissions,
    oneOfPerm
  } = params; //requiredPermissions 存在需要被鉴权的情况

  if (Array.isArray(requiredPermissions) && requiredPermissions.length) {
    let count = 0;

    for (const rp of requiredPermissions) {
      if (_auth(rp, userPermission)) {
        count++;
      }
    }

    return oneOfPerm ? count > 0 : count === requiredPermissions.length;
  }

  return true;
};

export default auth;