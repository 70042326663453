import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from 'axios';
import { BASE_URL } from './config';
const request = axios.create({
  baseURL: `${BASE_URL}design`,
  timeout: 60000
});
/**
 * 配置 request 请求拦截器
 */

request.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  const accessToken = localStorage.getItem('userToken');
  return { ...config,
    headers: {
      accessToken: accessToken || '',
      'Content-Type': 'application/json'
    }
  };
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});
/**
 * 配置 response 响应拦截器
 */

request.interceptors.response.use(response => {
  const {
    status,
    data: {
      code,
      data,
      msg
    }
  } = response;
  return new Promise(async (resolve, reject) => {
    if (status !== 200) {
      _Message.warning({
        content: '系统异常，请稍后再试～'
      });

      reject({
        code,
        msg
      });
      return;
    } // 请求内容判断


    if (code !== 0) {
      _Message.warning({
        content: msg || '登录超时'
      });

      code === 401 && (window.location.hash = '/login');
      reject({
        code,
        msg
      });
    } else {
      resolve(data);
    }
  });
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});
export default request;