export function isArray(val) {
  return Object.prototype.toString.call(val) === '[object Array]';
}
export function isObject(val) {
  return Object.prototype.toString.call(val) === '[object Object]';
}
export function isString(val) {
  return Object.prototype.toString.call(val) === '[object String]';
}
export function isPhone(val) {
  return /^1[3-9]\d{9}$/.test(val);
}
export const isSSR = function () {
  try {
    return !(typeof window !== 'undefined' && document !== undefined);
  } catch (e) {
    return true;
  }
}();