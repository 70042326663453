import request from './request';
const apiPostUrl = {
  login: '/login',
  // 登录
  logout: '/logout',
  // 登出
  updateImage: '/atlas/updated',
  // 图片编辑
  addClassify: '/classify',
  // 新建分类
  updatedClassify: '/classify/updated',
  // 修改分类
  addTag: '/tags',
  // 新建标签
  getImagesList: '/atlas/list' // 获取图片列表

};
const apiGetUrl = {
  imageDelete: '/atlas/deleted',
  // 图片删除
  imageCount: '/atlas/count-classified',
  // 图片统计
  getClassify: '/classify/tree',
  // 分类列表
  typeDelete: '/classify/deleted',
  // 删除分类
  getTagsTree: '/tags/tree',
  // 标签列表
  tagDelete: '/tags/deleted',
  // 标签删除
  usedTagsList: '/tags-used-recent/list',
  // 常用标签
  addTagsGroup: '/tags/add-group',
  // 新建标签分组
  updatedTags: '/tags/updated' // 修改标签

};
const apis = {};
Object.keys(apiPostUrl).forEach(key => {
  apis[key] = function (data, params) {
    const url = apiPostUrl[key];
    return request({
      url,
      method: 'POST',
      data,
      params
    });
  };
});
Object.keys(apiGetUrl).forEach(key => {
  apis[key] = function (params, data) {
    const url = apiGetUrl[key];
    return request({
      url,
      method: 'GET',
      params,
      data
    });
  };
});
export default apis;