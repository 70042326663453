import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  name: 'menu.home',
  key: 'home'
}, {
  name: 'menu.tag',
  key: 'tag'
}, {
  name: 'menu.type',
  key: 'type'
}, {
  name: 'menu.system',
  key: 'system',
  children: [{
    name: 'menu.system.account',
    key: 'system/account'
  }, {
    name: 'menu.system.role',
    key: 'system/role'
  }]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read', 'write'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    } else {
      result[item.name] = actions;
    }
  });
  return result;
}; // 根绝用户权限获取 可用路由以及默认路由

const useRoute = userPermission => {
  // 过滤路由
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) return []; // 循环路由对象

    for (const route of routes) {
      // 解构出当前路由的鉴权值
      const {
        requiredPermissions,
        oneOfPerm
      } = route; // 默认路由可见

      let visible = true; // 若有权限校验进行校验

      if (requiredPermissions) {
        // 开始鉴权 返回鉴权结果
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) continue; // 子路由遍历

      if ((route.children || []).length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    } // 返回鉴权后最新的路由


    return arr;
  }; // 初始化鉴权路由 配置的路由数据


  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [userPermission]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;